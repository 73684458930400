<template>
    <setting-block-layout
        :loading="loading"
        @save="onSave"
        @cancel="onCancel"
    >
        <template slot="body">
            <div class="setting_address">
                <div class="setting_address_input">
                    <form-input
                        v-model="$v.address.$model"
                        outlined
                        legend="Address"
                        :field.sync="address"
                        :errors="validationMessage($v.address)"
                        :is-valid="$v.address.$dirty && !$v.address.$anyError"
                        @input.native="$v.address.$touch()"
                        @blur.native="$v.address.$touch()"
                    />
                </div>
                <div class="setting_address_input">
                    <form-input
                        v-model="city"
                        legend="City"
                        :field.sync="city"
                        :errors="validationMessage($v.city)"
                        :is-valid="$v.city.$dirty && !$v.city.$anyError"
                        @input.native="$v.city.$touch()"
                        @blur.native="$v.city.$touch()"
                    />
                </div>
                <div class="setting_address_input">
                    <form-input
                        v-model="zip"
                        legend="ZIP"
                        :field.sync="zip"
                        :errors="validationMessage($v.zip)"
                        :is-valid="$v.zip.$dirty && !$v.zip.$anyError"
                        @input.native="$v.zip.$touch()"
                        @blur.native="$v.zip.$touch()"
                    />
                </div>
                <div class="setting_address_input">
                    <form-select-countries
                        v-model="$v.country.$model"
                        :country="country"
                        label="Country"
                        tabindex="0"
                        :field.sync="country"
                        :errors="validationMessage($v.country)"
                        :is-valid="!$v.country.$anyError && !$v.country.$invalid"
                        @input.native="$v.country.$touch()"
                        @blur.native="$v.country.$touch()"
                    />
                </div>
                <div
                    class="setting_address_input"
                >
                    <form-select-region
                        v-model="$v.region.$model"
                        :region="region"
                        :country="country"
                        label="Region"
                        tabindex="0"
                        :field.sync="region"
                        :errors="validationMessage($v.region)"
                        :is-valid="!$v.region.$anyError && !$v.region.$invalid"
                        @input.native="$v.region.$touch()"
                        @blur.native="$v.region.$touch()"
                        @checkRequired="regionsLength = $event"
                    />
                </div>
            </div>
        </template>
    </setting-block-layout>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { validationMessage } from 'vuelidate-messages'
import { createNamespacedHelpers } from 'vuex';
import formValidationMixin from '@/mixins/form-validation-mixin'
import { validationRules } from '@/validation/profile/profile-address/rules'
import { formMessages } from '@/validation/profile/profile-address/messages'

import { eventBus } from '@/helpers/event-bus/'
import SettingBlockLayout from '@/components/account-settings/SettingBlockLayout';
import {
    mapClientFields
} from '@/store/modules/client';
import {
    ACTION_CLIENT_UPDATE_ADDRESS
} from '@/store/modules/client/action-types';

const {
    mapActions: mapClientActions
} = createNamespacedHelpers('client');

export default {
    components: {
        SettingBlockLayout
    },
    mixins: [
        validationMixin,
        formValidationMixin
    ],
    metaInfo: {
        title: 'Change address'
    },
    data() {
        return {
            regionsLength: null,
            loading: false
        }
    },
    validations: {
        ...validationRules
    },
    computed: {
        ...mapClientFields([
            'client_data.address',
            'client_data.city',
            'client_data.zip',
            'client_data.country',
            'client_data.region'
        ])
    },
    watch: {
        country() {
            this.region = ''
        }
    },
    methods: {
        validationMessage: validationMessage(formMessages),
        ...mapClientActions({
            updateAddress: ACTION_CLIENT_UPDATE_ADDRESS
        }),
        async onSave() {
            this.$v.$touch()
            if (this.$v.$invalid) {
                eventBus.$emit('showSnackBar', 'Please complete the form', 'error');
                return
            }
            try {
                this.loading = true
                await this.updateAddress({
                    address: this.address,
                    city: this.city,
                    zip: this.zip,
                    country: this.country,
                    region: this.region
                })
                eventBus.$emit('showSnackBar', 'Your address has been changed', 'success');
                this.$router.push({ name: 'profile' })
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error');
            } finally {
                this.loading = false
            }
        },
        onCancel() {
            this.$router.push({ name: 'profile' })
        }
    }
}
</script>

<style lang="scss">
    .setting_address{
        display: flex;
        flex-wrap: wrap;
        margin-left: -15px;
        margin-right: -15px;
        &_input {
            width: calc(50% - 30px);
            margin: 15px;
            &:nth-child(1) {
                margin-top: 0;
            }
            &:nth-child(2) {
                margin-top: 0;
                @media all and (max-width: 768px) {
                    margin-top: 15px;
                }
            }
            &:last-child {
                margin-bottom: 0;
            }
            @media all and (max-width: 768px) {
                width: 100%;
                margin: 15px 0;
            }
        }
    }
</style>
